;(function(window, document, undefined){

    var tests = [];
    var ModernizrProto = {
        _version: '3.6.0',
        _config: {
            'classPrefix': '',
            'enableClasses': true,
            'enableJSClass': true,
            'usePrefixes': true
        },
        _q: [],
        on: function(test, cb) {
            var self = this;
            setTimeout(function() {
                cb(self[test]);
            }, 0);
        },
        addTest: function(name, fn, options) {
            tests.push({name: name, fn: fn, options: options});
        },
        addAsyncTest: function(fn) {
            tests.push({name: null, fn: fn});
        }
    };

    var Modernizr = function() {};
    Modernizr.prototype = ModernizrProto;
    Modernizr = new Modernizr();
    var classes = [];

    function is(obj, type) {
        return typeof obj === type;
    }

    ;
    function testRunner() {
        var featureNames;
        var feature;
        var aliasIdx;
        var result;
        var nameIdx;
        var featureName;
        var featureNameSplit;

        for (var featureIdx in tests) {
            if (tests.hasOwnProperty(featureIdx)) {
                featureNames = [];
                feature = tests[featureIdx];
                if (feature.name) {
                    featureNames.push(feature.name.toLowerCase());
                    if (feature.options && feature.options.aliases && feature.options.aliases.length) {
                        for (aliasIdx = 0; aliasIdx < feature.options.aliases.length; aliasIdx++) {
                            featureNames.push(feature.options.aliases[aliasIdx].toLowerCase());
                        }
                    }
                }

                result = is(feature.fn, 'function') ? feature.fn() : feature.fn;
                for (nameIdx = 0; nameIdx < featureNames.length; nameIdx++) {
                    featureName = featureNames[nameIdx];
                    featureNameSplit = featureName.split('.');

                    if (featureNameSplit.length === 1) {
                        Modernizr[featureNameSplit[0]] = result;
                    } else {
                        if (Modernizr[featureNameSplit[0]] && !(Modernizr[featureNameSplit[0]] instanceof Boolean)) {
                            Modernizr[featureNameSplit[0]] = new Boolean(Modernizr[featureNameSplit[0]]);
                        }
                        Modernizr[featureNameSplit[0]][featureNameSplit[1]] = result;
                    }
                    classes.push((result ? '' : 'no-') + featureNameSplit.join('-'));
                }
            }
        }
    }
    ;
    var docElement = document.documentElement;
    var isSVG = docElement.nodeName.toLowerCase() === 'svg';

    function setClasses(classes) {
        var className = docElement.className;
        var classPrefix = Modernizr._config.classPrefix || '';
        if (isSVG) {
            className = className.baseVal;
        }
        if (Modernizr._config.enableJSClass) {
            var reJS = new RegExp('(^|\\s)' + classPrefix + 'no-js(\\s|$)');
            className = className.replace(reJS, '$1' + classPrefix + 'js$2');
        }
        if (Modernizr._config.enableClasses) {
            if (classes.length > 0) {
                className += ' ' + classPrefix + classes.join(' ' + classPrefix);
            }
            if (isSVG) {
                docElement.className.baseVal = className;
            } else {
                docElement.className = className;
            }
        }
    }

    ;
    var hasOwnProp;

    (function() {
        var _hasOwnProperty = ({}).hasOwnProperty;
        if (!is(_hasOwnProperty, 'undefined') && !is(_hasOwnProperty.call, 'undefined')) {
            hasOwnProp = function(object, property) {
                return _hasOwnProperty.call(object, property);
            };
        }
        else {
            hasOwnProp = function(object, property) { /* yes, this can give false positives/negatives, but most of the time we don't care about those */
                return ((property in object) && is(object.constructor.prototype[property], 'undefined'));
            };
        }
    })();

    ModernizrProto._l = {};

    ModernizrProto.on = function(feature, cb) {
        // Create the list of listeners if it doesn't exist
        if (!this._l[feature]) {
            this._l[feature] = [];
        }
        // Push this test on to the listener list
        this._l[feature].push(cb);
        // If it's already been resolved, trigger it on next tick
        if (Modernizr.hasOwnProperty(feature)) {
            // Next Tick
            setTimeout(function() {
                Modernizr._trigger(feature, Modernizr[feature]);
            }, 0);
        }
    };

    ModernizrProto._trigger = function(feature, res) {
        if (!this._l[feature]) {
            return;
        }
        var cbs = this._l[feature];
        setTimeout(function() {
            var i, cb;
            for (i = 0; i < cbs.length; i++) {
                cb = cbs[i];
                cb(res);
            }
        }, 0);
        delete this._l[feature];
    };

    function addTest(feature, test) {
        if (typeof feature === 'object') {
            for (var key in feature) {
                if (hasOwnProp(feature, key)) {
                    addTest(key, feature[ key ]);
                }
            }
        } else {
            feature = feature.toLowerCase();
            var featureNameSplit = feature.split('.');
            var last = Modernizr[featureNameSplit[0]];
            // Again, we don't check for parent test existence. Get that right, though.
            if (featureNameSplit.length === 2) {
                last = last[featureNameSplit[1]];
            }
            if (typeof last !== 'undefined') {
                return Modernizr;
            }
            test = typeof test === 'function' ? test() : test;
            // Set the value (this is the magic, right here).
            if (featureNameSplit.length === 1) {
                Modernizr[featureNameSplit[0]] = test;
            } else {
                // cast to a Boolean, if not one already
                if (Modernizr[featureNameSplit[0]] && !(Modernizr[featureNameSplit[0]] instanceof Boolean)) {
                    Modernizr[featureNameSplit[0]] = new Boolean(Modernizr[featureNameSplit[0]]);
                }
                Modernizr[featureNameSplit[0]][featureNameSplit[1]] = test;
            }
            setClasses([(!!test && test !== false ? '' : 'no-') + featureNameSplit.join('-')]);
            Modernizr._trigger(feature, test);
        }
        return Modernizr; // allow chaining.
    }

    // After all the tests are run, add self to the Modernizr prototype
    Modernizr._q.push(function() {
        ModernizrProto.addTest = addTest;
    });

    var omPrefixes = 'Moz O ms Webkit';
    var cssomPrefixes = (ModernizrProto._config.usePrefixes ? omPrefixes.split(' ') : []);
    ModernizrProto._cssomPrefixes = cssomPrefixes;

    var atRule = function(prop) {
        var length = prefixes.length;
        var cssrule = window.CSSRule;
        var rule;

        if (typeof cssrule === 'undefined') {
            return undefined;
        }
        if (!prop) {
            return false;
        }
        // remove literal @ from beginning of provided property
        prop = prop.replace(/^@/, '');
        // CSSRules use underscores instead of dashes
        rule = prop.replace(/-/g, '_').toUpperCase() + '_RULE';
        if (rule in cssrule) {
            return '@' + prop;
        }
        for (var i = 0; i < length; i++) {
            // prefixes gives us something like -o-, and we want O_
            var prefix = prefixes[i];
            var thisRule = prefix.toUpperCase() + '_' + rule;

            if (thisRule in cssrule) {
                return '@-' + prefix.toLowerCase() + '-' + prop;
            }
        }
        return false;
    };

    ModernizrProto.atRule = atRule;

    var domPrefixes = (ModernizrProto._config.usePrefixes ? omPrefixes.toLowerCase().split(' ') : []);
    ModernizrProto._domPrefixes = domPrefixes;

    function createElement() {
        if (typeof document.createElement !== 'function') {
            return document.createElement(arguments[0]);
        } else if (isSVG) {
            return document.createElementNS.call(document, 'http://www.w3.org/2000/svg', arguments[0]);
        } else {
            return document.createElement.apply(document, arguments);
        }
    }
    ;

    var hasEvent = (function() {
        var needsFallback = !('onblur' in docElement);

        function inner(eventName, element) {
            var isSupported;
            if (!eventName) { return false; }
            if (!element || typeof element === 'string') {
                element = createElement(element || 'div');
            }

            eventName = 'on' + eventName;
            isSupported = eventName in element;
            // Fallback technique for old Firefox - bit.ly/event-detection
            if (!isSupported && needsFallback) {
                if (!element.setAttribute) {
                    element = createElement('div');
                }

                element.setAttribute(eventName, '');
                isSupported = typeof element[eventName] === 'function';

                if (element[eventName] !== undefined) {
                    element[eventName] = undefined;
                }
                element.removeAttribute(eventName);
            }
            return isSupported;
        }
        return inner;
    })();

    ModernizrProto.hasEvent = hasEvent;

    var html5;
    if (!isSVG) {
        ;(function(window, document) {
            var version = '3.7.3';
            var options = window.html5 || {};
            var reSkip = /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i;
            var saveClones = /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i;
            var supportsHtml5Styles;
            var expando = '_html5shiv';
            var expanID = 0;
            var expandoData = {};
            var supportsUnknownElements;
            (function() {
                try {
                    var a = document.createElement('a');
                    a.innerHTML = '<xyz></xyz>';
                    //if the hidden property is implemented we can assume, that the browser supports basic HTML5 Styles
                    supportsHtml5Styles = ('hidden' in a);
                    supportsUnknownElements = a.childNodes.length == 1 || (function() {
                        // assign a false positive if unable to shiv
                        (document.createElement)('a');
                        var frag = document.createDocumentFragment();
                        return (
                            typeof frag.cloneNode == 'undefined' ||
                            typeof frag.createDocumentFragment == 'undefined' ||
                            typeof frag.createElement == 'undefined'
                        );
                    }());
                } catch(e) {
                    // assign a false positive if detection fails => unable to shiv
                    supportsHtml5Styles = true;
                    supportsUnknownElements = true;
                }
            }());

            function addStyleSheet(ownerDocument, cssText) {
                var p = ownerDocument.createElement('p'),
                    parent = ownerDocument.getElementsByTagName('head')[0] || ownerDocument.documentElement;
                p.innerHTML = 'x<style>' + cssText + '</style>';
                return parent.insertBefore(p.lastChild, parent.firstChild);
            }

            function getElements() {
                var elements = html5.elements;
                return typeof elements == 'string' ? elements.split(' ') : elements;
            }

            function addElements(newElements, ownerDocument) {
                var elements = html5.elements;
                if(typeof elements != 'string'){
                    elements = elements.join(' ');
                }
                if(typeof newElements != 'string'){
                    newElements = newElements.join(' ');
                }
                html5.elements = elements +' '+ newElements;
                shivDocument(ownerDocument);
            }

            function getExpandoData(ownerDocument) {
                var data = expandoData[ownerDocument[expando]];
                if (!data) {
                    data = {};
                    expanID++;
                    ownerDocument[expando] = expanID;
                    expandoData[expanID] = data;
                }
                return data;
            }

            function createElement(nodeName, ownerDocument, data){
                if (!ownerDocument) {
                    ownerDocument = document;
                }
                if(supportsUnknownElements){
                    return ownerDocument.createElement(nodeName);
                }
                if (!data) {
                    data = getExpandoData(ownerDocument);
                }
                var node;
                if (data.cache[nodeName]) {
                    node = data.cache[nodeName].cloneNode();
                } else if (saveClones.test(nodeName)) {
                    node = (data.cache[nodeName] = data.createElem(nodeName)).cloneNode();
                } else {
                    node = data.createElem(nodeName);
                }
                return node.canHaveChildren && !reSkip.test(nodeName) && !node.tagUrn ? data.frag.appendChild(node) : node;
            }

            function createDocumentFragment(ownerDocument, data){
                if (!ownerDocument) {
                    ownerDocument = document;
                }
                if(supportsUnknownElements){
                    return ownerDocument.createDocumentFragment();
                }
                data = data || getExpandoData(ownerDocument);
                var clone = data.frag.cloneNode(),
                    i = 0,
                    elems = getElements(),
                    l = elems.length;
                for(;i<l;i++){
                    clone.createElement(elems[i]);
                }
                return clone;
            }

            function shivMethods(ownerDocument, data) {
                if (!data.cache) {
                    data.cache = {};
                    data.createElem = ownerDocument.createElement;
                    data.createFrag = ownerDocument.createDocumentFragment;
                    data.frag = data.createFrag();
                }

                ownerDocument.createElement = function(nodeName) {
                    if (!html5.shivMethods) {
                        return data.createElem(nodeName);
                    }
                    return createElement(nodeName, ownerDocument, data);
                };

                ownerDocument.createDocumentFragment = Function('h,f', 'return function(){' +
                    'var n=f.cloneNode(),c=n.createElement;' +
                    'h.shivMethods&&(' +
                    getElements().join().replace(/[\w\-:]+/g, function(nodeName) {
                        data.createElem(nodeName);
                        data.frag.createElement(nodeName);
                        return 'c("' + nodeName + '")';
                    }) +
                    ');return n}'
                )(html5, data.frag);
            }

            function shivDocument(ownerDocument) {
                if (!ownerDocument) {
                    ownerDocument = document;
                }
                var data = getExpandoData(ownerDocument);

                if (html5.shivCSS && !supportsHtml5Styles && !data.hasCSS) {
                    data.hasCSS = !!addStyleSheet(ownerDocument,
                        // corrects block display not defined in IE6/7/8/9
                        'article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}' +
                        // adds styling not present in IE6/7/8/9
                        'mark{background:#FF0;color:#000}' +
                        // hides non-rendered elements
                        'template{display:none}'
                    );
                }
                if (!supportsUnknownElements) {
                    shivMethods(ownerDocument, data);
                }
                return ownerDocument;
            }
            var html5 = {
                'elements': options.elements || 'abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video',
                'version': version,
                'shivCSS': (options.shivCSS !== false),
                'supportsUnknownElements': supportsUnknownElements,
                'shivMethods': (options.shivMethods !== false),
                'type': 'default',
                'shivDocument': shivDocument,
                createElement: createElement,
                createDocumentFragment: createDocumentFragment,
                addElements: addElements
            };

            window.html5 = html5;
            shivDocument(document);
            var reMedia = /^$|\b(?:all|print)\b/;
            var shivNamespace = 'html5shiv';
            var supportsShivableSheets = !supportsUnknownElements && (function() {
                // assign a false negative if unable to shiv
                var docEl = document.documentElement;
                return !(
                    typeof document.namespaces == 'undefined' ||
                    typeof document.parentWindow == 'undefined' ||
                    typeof docEl.applyElement == 'undefined' ||
                    typeof docEl.removeNode == 'undefined' ||
                    typeof window.attachEvent == 'undefined'
                );
            }());

            function addWrappers(ownerDocument) {
                var node,
                    nodes = ownerDocument.getElementsByTagName('*'),
                    index = nodes.length,
                    reElements = RegExp('^(?:' + getElements().join('|') + ')$', 'i'),
                    result = [];

                while (index--) {
                    node = nodes[index];
                    if (reElements.test(node.nodeName)) {
                        result.push(node.applyElement(createWrapper(node)));
                    }
                }
                return result;
            }

            function createWrapper(element) {
                var node,
                    nodes = element.attributes,
                    index = nodes.length,
                    wrapper = element.ownerDocument.createElement(shivNamespace + ':' + element.nodeName);
                // copy element attributes to the wrapper
                while (index--) {
                    node = nodes[index];
                    node.specified && wrapper.setAttribute(node.nodeName, node.nodeValue);
                }
                // copy element styles to the wrapper
                wrapper.style.cssText = element.style.cssText;
                return wrapper;
            }

            function shivCssText(cssText) {
                var pair,
                    parts = cssText.split('{'),
                    index = parts.length,
                    reElements = RegExp('(^|[\\s,>+~])(' + getElements().join('|') + ')(?=[[\\s,>+~#.:]|$)', 'gi'),
                    replacement = '$1' + shivNamespace + '\\:$2';

                while (index--) {
                    pair = parts[index] = parts[index].split('}');
                    pair[pair.length - 1] = pair[pair.length - 1].replace(reElements, replacement);
                    parts[index] = pair.join('}');
                }
                return parts.join('{');
            }

            function removeWrappers(wrappers) {
                var index = wrappers.length;
                while (index--) {
                    wrappers[index].removeNode();
                }
            }

            function shivPrint(ownerDocument) {
                var shivedSheet,
                    wrappers,
                    data = getExpandoData(ownerDocument),
                    namespaces = ownerDocument.namespaces,
                    ownerWindow = ownerDocument.parentWindow;

                if (!supportsShivableSheets || ownerDocument.printShived) {
                    return ownerDocument;
                }
                if (typeof namespaces[shivNamespace] == 'undefined') {
                    namespaces.add(shivNamespace);
                }

                function removeSheet() {
                    clearTimeout(data._removeSheetTimer);
                    if (shivedSheet) {
                        shivedSheet.removeNode(true);
                    }
                    shivedSheet= null;
                }

                ownerWindow.attachEvent('onbeforeprint', function() {
                    removeSheet();
                    var imports,
                        length,
                        sheet,
                        collection = ownerDocument.styleSheets,
                        cssText = [],
                        index = collection.length,
                        sheets = Array(index);

                    // convert styleSheets collection to an array
                    while (index--) {
                        sheets[index] = collection[index];
                    }
                    // concat all style sheet CSS text
                    while ((sheet = sheets.pop())) {
                        // IE does not enforce a same origin policy for external style sheets...
                        // but has trouble with some dynamically created stylesheets
                        if (!sheet.disabled && reMedia.test(sheet.media)) {
                            try {
                                imports = sheet.imports;
                                length = imports.length;
                            } catch(er){
                                length = 0;
                            }

                            for (index = 0; index < length; index++) {
                                sheets.push(imports[index]);
                            }

                            try {
                                cssText.push(sheet.cssText);
                            } catch(er){}
                        }
                    }
                    // wrap all HTML5 elements with printable elements and add the shived style sheet
                    cssText = shivCssText(cssText.reverse().join(''));
                    wrappers = addWrappers(ownerDocument);
                    shivedSheet = addStyleSheet(ownerDocument, cssText);

                });
                ownerWindow.attachEvent('onafterprint', function() {
                    // remove wrappers, leaving the original elements, and remove the shived style sheet
                    removeWrappers(wrappers);
                    clearTimeout(data._removeSheetTimer);
                    data._removeSheetTimer = setTimeout(removeSheet, 500);
                });
                ownerDocument.printShived = true;
                return ownerDocument;
            }
            // expose API
            html5.type += ' print';
            html5.shivPrint = shivPrint;
            // shiv for print
            shivPrint(document);
            if(typeof module == 'object' && module.exports){
                module.exports = html5;
            }
        }(typeof window !== "undefined" ? window : this, document));
    }

    ;

    var err = function() {};
    var warn = function() {};

    if (window.console) {
        err = function() {
            var method = console.error ? 'error' : 'log';
            window.console[method].apply(window.console, Array.prototype.slice.call(arguments));
        };
        warn = function() {
            var method = console.warn ? 'warn' : 'log';
            window.console[method].apply(window.console, Array.prototype.slice.call(arguments));
        };
    }

    ModernizrProto.load = function() {
        if ('yepnope' in window) {
            warn('yepnope.js (aka Modernizr.load) is no longer included as part of Modernizr. yepnope appears to be available on the page, so we’ll use it to handle this call to Modernizr.load, but please update your code to use yepnope directly.\n See http://github.com/Modernizr/Modernizr/issues/1182 for more information.');
            window.yepnope.apply(window, [].slice.call(arguments, 0));
        } else {
            err('yepnope.js (aka Modernizr.load) is no longer included as part of Modernizr. Get it from http://yepnopejs.com. See http://github.com/Modernizr/Modernizr/issues/1182 for more information.');
        }
    };

    function getBody() {
        var body = document.body;
        if (!body) {
            body = createElement(isSVG ? 'svg' : 'body');
            body.fake = true;
        }
        return body;
    }

    ;
    function injectElementWithStyles(rule, callback, nodes, testnames) {
        var mod = 'modernizr';
        var style;
        var ret;
        var node;
        var docOverflow;
        var div = createElement('div');
        var body = getBody();

        if (parseInt(nodes, 10)) {
            while (nodes--) {
                node = createElement('div');
                node.id = testnames ? testnames[nodes] : mod + (nodes + 1);
                div.appendChild(node);
            }
        }

        style = createElement('style');
        style.type = 'text/css';
        style.id = 's' + mod;
        (!body.fake ? div : body).appendChild(style);
        body.appendChild(div);

        if (style.styleSheet) {
            style.styleSheet.cssText = rule;
        } else {
            style.appendChild(document.createTextNode(rule));
        }
        div.id = mod;
        if (body.fake) {
            //avoid crashing IE8, if background image is used
            body.style.background = '';
            //Safari 5.13/5.1.4 OSX stops loading if ::-webkit-scrollbar is used and scrollbars are visible
            body.style.overflow = 'hidden';
            docOverflow = docElement.style.overflow;
            docElement.style.overflow = 'hidden';
            docElement.appendChild(body);
        }
        ret = callback(div, rule);
        if (body.fake) {
            body.parentNode.removeChild(body);
            docElement.style.overflow = docOverflow;
            docElement.offsetHeight;
        } else {
            div.parentNode.removeChild(div);
        }
        return !!ret;
    }

    ;
    var mq = (function() {
        var matchMedia = window.matchMedia || window.msMatchMedia;
        if (matchMedia) {
            return function(mq) {
                var mql = matchMedia(mq);
                return mql && mql.matches || false;
            };
        }
        return function(mq) {
            var bool = false;
            injectElementWithStyles('@media ' + mq + ' { #modernizr { position: absolute; } }', function(node) {
                bool = (window.getComputedStyle ?
                    window.getComputedStyle(node, null) :
                    node.currentStyle).position === 'absolute';
            });
            return bool;
        };
    })();

    ModernizrProto.mq = mq;

    function contains(str, substr) {
        return !!~('' + str).indexOf(substr);
    }

    ;
    var modElem = {
        elem: createElement('modernizr')
    };

    Modernizr._q.push(function() {
        delete modElem.elem;
    });

    var mStyle = {
        style: modElem.elem.style
    };

    Modernizr._q.unshift(function() {
        delete mStyle.style;
    });

    function domToCSS(name) {
        return name.replace(/([A-Z])/g, function(str, m1) {
            return '-' + m1.toLowerCase();
        }).replace(/^ms-/, '-ms-');
    }

    ;
    function computedStyle(elem, pseudo, prop) {
        var result;
        if ('getComputedStyle' in window) {
            result = getComputedStyle.call(window, elem, pseudo);
            var console = window.console;

            if (result !== null) {
                if (prop) {
                    result = result.getPropertyValue(prop);
                }
            } else {
                if (console) {
                    var method = console.error ? 'error' : 'log';
                    console[method].call(console, 'getComputedStyle returning null, its possible modernizr test results are inaccurate');
                }
            }
        } else {
            result = !pseudo && elem.currentStyle && elem.currentStyle[prop];
        }
        return result;
    }

    ;
    function nativeTestProps(props, value) {
        var i = props.length;
        if ('CSS' in window && 'supports' in window.CSS) {
            while (i--) {
                if (window.CSS.supports(domToCSS(props[i]), value)) {
                    return true;
                }
            }
            return false;
        }
        else if ('CSSSupportsRule' in window) {
            var conditionText = [];
            while (i--) {
                conditionText.push('(' + domToCSS(props[i]) + ':' + value + ')');
            }
            conditionText = conditionText.join(' or ');
            return injectElementWithStyles('@supports (' + conditionText + ') { #modernizr { position: absolute; } }', function(node) {
                return computedStyle(node, null, 'position') === 'absolute';
            });
        }
        return undefined;
    }
    ;
    function cssToDOM(name) {
        return name.replace(/([a-z])-([a-z])/g, function(str, m1, m2) {
            return m1 + m2.toUpperCase();
        }).replace(/^-/, '');
    }

    ;
    function testProps(props, prefixed, value, skipValueTest) {
        skipValueTest = is(skipValueTest, 'undefined') ? false : skipValueTest;
        if (!is(value, 'undefined')) {
            var result = nativeTestProps(props, value);
            if (!is(result, 'undefined')) {
                return result;
            }
        }
        var afterInit, i, propsLength, prop, before;
        var elems = ['modernizr', 'tspan', 'samp'];
        while (!mStyle.style && elems.length) {
            afterInit = true;
            mStyle.modElem = createElement(elems.shift());
            mStyle.style = mStyle.modElem.style;
        }
        function cleanElems() {
            if (afterInit) {
                delete mStyle.style;
                delete mStyle.modElem;
            }
        }

        propsLength = props.length;
        for (i = 0; i < propsLength; i++) {
            prop = props[i];
            before = mStyle.style[prop];

            if (contains(prop, '-')) {
                prop = cssToDOM(prop);
            }

            if (mStyle.style[prop] !== undefined) {
                if (!skipValueTest && !is(value, 'undefined')) {
                    try {
                        mStyle.style[prop] = value;
                    } catch (e) {}
                    if (mStyle.style[prop] !== before) {
                        cleanElems();
                        return prefixed === 'pfx' ? prop : true;
                    }
                }
                else {
                    cleanElems();
                    return prefixed === 'pfx' ? prop : true;
                }
            }
        }
        cleanElems();
        return false;
    }

    ;
    function fnBind(fn, that) {
        return function() {
            return fn.apply(that, arguments);
        };
    }

    ;
    function testDOMProps(props, obj, elem) {
        var item;

        for (var i in props) {
            if (props[i] in obj) {
                if (elem === false) {
                    return props[i];
                }

                item = obj[props[i]];
                if (is(item, 'function')) {
                    // bind to obj unless overridden
                    return fnBind(item, elem || obj);
                }
                return item;
            }
        }
        return false;
    }

    ;
    function testPropsAll(prop, prefixed, elem, value, skipValueTest) {

        var ucProp = prop.charAt(0).toUpperCase() + prop.slice(1),
            props = (prop + ' ' + cssomPrefixes.join(ucProp + ' ') + ucProp).split(' ');
        if (is(prefixed, 'string') || is(prefixed, 'undefined')) {
            return testProps(props, prefixed, value, skipValueTest);
        } else {
            props = (prop + ' ' + (domPrefixes).join(ucProp + ' ') + ucProp).split(' ');
            return testDOMProps(props, prefixed, elem);
        }
    }
    ModernizrProto.testAllProps = testPropsAll;

    var prefixed = ModernizrProto.prefixed = function(prop, obj, elem) {
        if (prop.indexOf('@') === 0) {
            return atRule(prop);
        }

        if (prop.indexOf('-') !== -1) {
            // Convert kebab-case to camelCase
            prop = cssToDOM(prop);
        }
        if (!obj) {
            return testPropsAll(prop, 'pfx');
        } else {
            return testPropsAll(prop, obj, elem);
        }
    };

    var prefixes = (ModernizrProto._config.usePrefixes ? ' -webkit- -moz- -o- -ms- '.split(' ') : ['','']);
    ModernizrProto._prefixes = prefixes;

    var prefixedCSS = ModernizrProto.prefixedCSS = function(prop) {
        var prefixedProp = prefixed(prop);
        return prefixedProp && domToCSS(prefixedProp);
    };

    function testAllProps(prop, value, skipValueTest) {
        return testPropsAll(prop, undefined, undefined, value, skipValueTest);
    }

    ModernizrProto.testAllProps = testAllProps;
    var testProp = ModernizrProto.testProp = function(prop, value, useValue) {
        return testProps([prop], undefined, value, useValue);
    };
    var testStyles = ModernizrProto.testStyles = injectElementWithStyles;

    Modernizr.addTest('localstorage', function() {
        var mod = 'modernizr';
        try {
            localStorage.setItem(mod, mod);
            localStorage.removeItem(mod);
            return true;
        } catch(e) {
            return false;
        }
    });

    Modernizr.addTest('sessionstorage', function() {
        var mod = 'modernizr';
        try {
            sessionStorage.setItem(mod, mod);
            sessionStorage.removeItem(mod);
            return true;
        } catch(e) {
            return false;
        }
    })

    Modernizr.addTest('svg', !!document.createElementNS && !!document.createElementNS('http://www.w3.org/2000/svg', 'svg').createSVGRect);

    Modernizr.addTest('mediaqueries', Modernizr.mq('only all'));

    Modernizr.addTest('placeholder', function(){
        return !!( 'placeholder' in ( Modernizr.input    || document.createElement('input')    ) &&
            'placeholder' in ( Modernizr.textarea || document.createElement('textarea') )
        );
    });

    Modernizr.addTest('touch', function() {
        var bool, mod = 'modernizr';
        if(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            bool = true;
        } else {
            injectElementWithStyles(['@media (',prefixes.join('touch-enabled),('),mod,')','{#modernizr{top:9px;position:absolute}}'].join(''), function( node ) {
                bool = node.offsetTop === 9;
            });
        }
        return bool;
    });

    Modernizr.addTest('hashchange', function() {
        if (hasEvent('hashchange', window) === false) {
            return false;
        }
        return (document.documentMode === undefined || document.documentMode > 7);
    });


    Modernizr.addTest('history', function() {
        return !!(window.history && history.pushState);
    });

    Modernizr.addTest('rgba', function() {
        var style = createElement('a').style;
        style.cssText = 'background-color:rgba(150,255,150,.5)';

        return ('' + style.backgroundColor).indexOf('rgba') > -1;
    });

    Modernizr.addTest('csstransitions', testAllProps('transition', 'all', true));

    Modernizr.addTest('mediaqueries', mq('only all'));

    Modernizr.addTest('placeholder', ('placeholder' in createElement('input') && 'placeholder' in createElement('textarea')));

// data uri test.
// https://github.com/Modernizr/Modernizr/issues/14
// in IE7 in HTTPS this can cause a Mixed Content security popup.
//  github.com/Modernizr/Modernizr/issues/362
// To avoid that you can create a new iframe and inject this.. perhaps..
    (function(){

        var datauri = new Image();


        datauri.onerror = function() {
            Modernizr.addTest('datauri', function () { return false; });
        };
        datauri.onload = function() {
            Modernizr.addTest('datauri', function () { return (datauri.width == 1 && datauri.height == 1); });
        };

        datauri.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";

    })();

// CUSTOM TESTS ADDED BY CSE, NOT PART OF CUSTOM BUILD FROM MODERNIZR, DO NOT DELETE //
    Modernizr.addTest('browserCss', function () {
        var isIE = /*@cc_on!@*/false || !!document.documentMode, browserName;
        if((!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0){
            browserName = 'opera';
        } else if(typeof InstallTrigger !== 'undefined') {
            browserName = 'firefox';
        } else if(/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object " +
            "SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' &&
            safari.pushNotification))) {
            browserName = 'safari'
        } else if(!isIE && !!window.StyleMedia) {
            browserName = 'edge';
        } else if(!!window.MSInputMethodContext && !!document.documentMode) {
            browserName = 'ie11'; //IE11
        } else if(isIE) {
            browserName = 'lt-ie11';
        } else if(!!window.chrome && !!window.chrome.webstore) {
            browserName = 'chrome';
        }
        document.documentElement.className += ' ' + browserName;
    });
//Windows 8 touch test
    Modernizr.addTest('win8touch', !!navigator.msMaxTouchPoints);
//Hi-Res Resolution Detection
    Modernizr.addTest('highres', function() {
        // for opera
        var ratio = '2.99/2';
        // for webkit
        var num = '1.499';
        var mqs = [
            'only screen and (-o-min-device-pixel-ratio:' + ratio + ')',
            'only screen and (min--moz-device-pixel-ratio:' + num + ')',
            'only screen and (-webkit-min-device-pixel-ratio:' + num + ')',
            'only screen and (min-device-pixel-ratio:' + num + ')'
        ];
        var isHighRes = false;
        for (var i = mqs.length - 1; i >= 0; i--) {
            isHighRes = Modernizr.mq( mqs[i] );
            // if found one, return early
            if ( isHighRes ) {
                return isHighRes;
            }
        }
        // not highres
        return isHighRes;
    });

    testRunner();
    setClasses(classes);
    delete ModernizrProto.addTest;
    delete ModernizrProto.addAsyncTest;
    for (var i = 0; i < Modernizr._q.length; i++) {
        Modernizr._q[i]();
    }
    window.Modernizr = Modernizr;
    ;
})(window, document);